export default {
    // %REMOVE_START%  embed   watermark tliyoutube2
    // The configuration options below are needed when running CKEditor from source files. 
    plugins: 'dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc,embed,embedbase,image,textindent',
    skin: 'moono-lisa',
    // %REMOVE_END%
    // Define changes to default configuration here. For example:
    language: 'zh-cn',
    // language: 'en',
    // uiColor: '#AADC6E',
    // 撤回次数
    undoStackSize: 20,
    // 图片上传相关
    filebrowserImageUploadUrl: "/v1/common/upload/file?",
    // filebrowserImageBrowseUrl
    // filebrowserBrowseUrl: '/#/browse-image?type=Files',
    // imageBrowser_listUrl: "/upload.html?type=files",
    image_previewText: ' ', // 图片信息面板预览区内容的文字内容，默认显示CKEditor自带的内容
    removeDialogTabs: 'link:target;link:advanced;image:advanced;image:Link;table:advanced', // 移除图片上传页面的'高级','链接'页签
    height: 500,
    toolbar: [{
            name: 'document',
            items: ['Source', 'Preview']
        },
        {
            name: 'clipboard',
            items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo']
        },
        {
            name: 'editing',
            items: ['Find', 'Replace', '-', 'SelectAll']
        },
        {
            name: 'basicstyles',
            items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat']
        },
        {
            name: 'paragraph',
            items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'textindent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl']
        },
        {
            name: 'links',
            items: ['Link', 'Unlink', 'Anchor']
        },
        {
            name: 'insert',
            items: ['Image', 'tliyoutube2', 'Embed', 'Table', 'HorizontalRule', 'SpecialChar', 'imagebrowser']
        },
        '/',
        {
            name: 'styles',
            items: ['Styles', 'Format', 'Font', 'FontSize', 'lineheight']
        },
        {
            name: 'colors',
            items: ['TextColor', 'BGColor']
        },
        {
            name: 'tools',
            items: ['Maximize', 'ShowBlocks']
        },
    ],
    removeButtons: 'Templates,NewPage,Print,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,Language,Flash,PageBreak,Iframe,About,Smiley',
    allowedContent: true,
    indentOffset: 20,
}