<template>
  <div class="ckeditor">
    <textarea :id="id" :readonly="readOnlyMode"></textarea>
    <el-dialog :lock-scroll="true"
               custom-class="content-preview"
               :visible.sync="previewVisible"
               :show-close="false"
               top="5vh">
      <div class="main-content">
        <div class="title">{{title?title:"Article Title"}}</div>
        <div class="preview"
             v-html="value"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script src="/static/js/jquery.js"></script>
<script>
import config from "./config";
import { minBase64 } from "@/utils/util";
export default {
  name: "VueCkeditor",
  props: {
    value: {
      type: String,
      required: true
    },
    readOnlyMode: {
      type: Boolean,
      default: () => false
    },
    hasgoods: {
      type: Boolean,
      default: () => true
    },
    title: {}
  },
  data () {
    const checkWebLinkTarget = (rule, value, callback) => {
      if (this.webLink.good.length === 0 && this.webLink.article === "") {
        callback(new Error("请选择跳转目标"));
      }
      callback();
    };
    return {
      id: "ckEditor",
      config: config,
      hasChange: false,
      dialogWebLinkVisible: false,
      // dialogWebLinkEdit: false,
      // activeWebLink: null,
      previewVisible: false,
      webLink: {
        radio: "article",
        good: [],
        article: "",
        input: ""
      },
      webLinkRules: {
        input: [
          {
            required: true,
            message: "请输入跳转关键字",
            trigger: "change"
          }
        ],
        target: [
          {
            required: true,
            trigger: "blur",
            validator: checkWebLinkTarget
          }
        ]
      },
      goodData: [],
      articleData: []
    };
  },
  computed: {
    instance () {
      return window.CKEDITOR.instances[this.id];
    }
  },
  watch: {
    readOnlyMode (val) {
      this.instance.setReadOnly(val);
    }
  },
  methods: {
    init () {
      this.initCkEditor();
    },
    initCkEditor () {
      if (typeof CKEDITOR === "undefined") {
        console.log("CKEDITOR is missing (http://ckeditor.com/)");
        return;
      }
      window.CKEDITOR.replace(this.id, this.config);
      // window.CKEDITOR.replace(this.id);
      window.ins = this.instance;
      // Ckeditor contentDom event
      this.instance.on("contentDom", () => { });
      // Listen for instanceReady event
      this.instance.on("instanceReady", () => {
        this.$nextTick(() => {
          document.querySelector("iframe").contentWindow.document.querySelector("body").innerHTML = this.value;
          // $('iframe').contents().find("body").html(this.value);
        })
      });
      // Ckeditor change event
      this.instance.on("change", () => {
        this.hasChange = true;
        this.$emit("input", this.instance.getData());
      });

      // 点击保存 icon
      this.instance.on("save", evt => {
        // console.log("点击了保存icon, 待自定义到接口保存");
        evt.cancel();
        evt.stop();
      });
      // 点击预览 icon
      this.instance.on("contentPreview", evt => {
        this.previewVisible = true;
        evt.cancel();
      });
      // 添加 ckeditor 图片请求字段
      this.instance.on("fileUploadRequest", evt => {
        this.fullscreenLoading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.1)'
        });
        setTimeout(() => {
          this.fullscreenLoading.close();
        }, 10000);
        evt.stop(); // 取消默认请求
        var fileLoader = evt.data.fileLoader;
        minBase64(fileLoader.file, fileLoader.data).then(base64Img => {
          var $formData = new FormData();
          var minFile = dataURLtoFile(base64Img, fileLoader.file.name);
          //将base64转换为文件对象
          function dataURLtoFile (dataurl, filename) {
            var arr = dataurl.split(",");
            var mime = arr[0].match(/:(.*?);/)[1];
            var bstr = atob(arr[1]);
            var n = bstr.length;
            var u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            //转换成file对象
            return new File([u8arr], filename, { type: mime });
          }
          $formData.append("imgFile", minFile);
          if (localStorage.getItem("hasWaterMark") == "true") {
            $formData.append("isWatermark", "Y");
          }
          // $formData.append("isWatermark", "Y");
          fileLoader.xhr.send($formData);
        });
      });

      // 修改 接口响应字段
      this.instance.on("fileUploadResponse", evt => {
        var fileLoader = evt.data.fileLoader;
        var xhr = fileLoader.xhr;
        var res = JSON.parse(xhr.responseText);
        setTimeout(() => {
          this.fullscreenLoading.close();
        }, 12000)
        // 修改接口图片字段
        if (res.code == 0) {
          Object.assign(evt.data, {
            uploaded: 1,
            fileName: res.data.bucketName,
            url: res.data.url
          });
        } else {
          Object.assign(evt.data, {
            uploaded: 0,
            error: {
              message: res.message
            }
          });
        }
        this.fullscreenLoading.close();
        evt.stop();
      });

      // Registering the beforeDestroyed hook right after creating the instance
      this.$once("hook:beforeDestroy", () => {
        this.instance.destroy();
        clearInterval(this.timer);
      });
    },
    setContent (val) {
      this.instance.setData(val);
    },
    getContent () {
      this.instance.getData();
    },
  },
  watch:{
    // value(val){
    //   if(val){
    //     this.setContent(val)
    //   }
    // }
  },
  mounted() {
    this.init();
  },
  destroyed () {
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss">
#ckeditor {
  div:after {
    content: " ";
    display: inline-block;
    width: 100%;
  }
}
.content-preview {
  /* Font */
  /* Emoji fonts are added to visualise them nicely in Internet Explorer. */
  font-family: "PingFang SC", Helvetica, Arial, "Hiragino Sans GB",
    "Microsoft Yahei", STHeiTi, sans-serif;
  font-size: 16px;
  /* Text color */
  color: #333;

  /* Remove the background color to make it transparent. */
  // background-color: #fff;
  width: 375px;
  box-sizing: content-box;
  height: 750px;
  padding: 80px 30px;
  background: #333;
  border-radius: 30px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    background: #fff;
    height: 100%;
    padding: 15px 0;

    overflow: auto;
  }
  .main-content {
    padding: 0 15px;
  }

  .title {
    font-weight: bolder;
    font-size: 20px;
  }
  .preview {
    width: 100%;
    height: 100%;
  }
  .el-dialog__body::-webkit-scrollbar,
  .preview::-webkit-scrollbar {
    display: none;
  }
  .cke_editable {
    font-size: 13px;
    line-height: 1.6;

    /* Fix for missing scrollbars with RTL texts. (#10488) */
    word-wrap: break-word;
  }
  img {
    width: 375px;
    transform: translateX(-15px);
    height: auto;
  }
  iframe {
    width: 375px;
    transform: translateX(-15px);
    height: 212px;
  }
  p {
    font-size: 16px;
    word-wrap: break-word;
  }

  blockquote {
    font-style: italic;
    font-family: Georgia, Times, "Times New Roman", serif;
    padding: 2px 0;
    border-style: solid;
    border-color: #ccc;
    border-width: 0;
  }

  .cke_contents_ltr blockquote {
    padding-left: 20px;
    padding-right: 8px;
    border-left-width: 5px;
  }

  .cke_contents_rtl blockquote {
    padding-left: 8px;
    padding-right: 20px;
    border-right-width: 5px;
  }

  a {
    color: #0782c1;
  }

  ol,
  ul,
  dl {
    /* IE7: reset rtl list margin. (#7334) */
    *margin-right: 0px;
    /* Preserved spaces for list items with text direction different than the list. (#6249,#8049)*/
    padding: 0 40px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    line-height: 1.2;
  }

  hr {
    border: 0px;
    border-top: 1px solid #ccc;
  }

  img.right {
    border: 1px solid #ccc;
    float: right;
    margin-left: 15px;
    padding: 5px;
  }

  img.left {
    border: 1px solid #ccc;
    float: left;
    margin-right: 15px;
    padding: 5px;
  }

  pre {
    white-space: pre-wrap; /* CSS 2.1 */
    word-wrap: break-word; /* IE7 */
    -moz-tab-size: 4;
    tab-size: 4;
  }

  .marker {
    background-color: Yellow;
  }

  span[lang] {
    font-style: italic;
  }

  figure {
    text-align: center;
    outline: solid 1px #ccc;
    background: rgba(0, 0, 0, 0.05);
    padding: 10px;
    margin: 10px 20px;
    display: inline-block;
  }

  figure > figcaption {
    text-align: center;
    display: block; /* For IE8 */
  }

  a > img {
    padding: 1px;
    margin: 1px;
    border: none;
    outline: 1px solid #0782c1;
  }

  /* Widget Styles */
  .code-featured {
    border: 5px solid red;
  }

  .math-featured {
    padding: 20px;
    box-shadow: 0 0 2px rgba(200, 0, 0, 1);
    background-color: rgba(255, 0, 0, 0.05);
    margin: 10px;
  }

  .image-clean {
    border: 0;
    background: none;
    padding: 0;
  }

  .image-clean > figcaption {
    font-size: 0.9em;
    text-align: right;
  }

  .image-grayscale {
    background-color: white;
    color: #666;
  }

  .image-grayscale img,
  img.image-grayscale {
    filter: grayscale(100%);
  }

  .embed-240p {
    max-width: 426px;
    max-height: 240px;
    margin: 0 auto;
  }

  .embed-360p {
    max-width: 640px;
    max-height: 360px;
    margin: 0 auto;
  }

  .embed-480p {
    max-width: 854px;
    max-height: 480px;
    margin: 0 auto;
  }

  .embed-720p {
    max-width: 1280px;
    max-height: 720px;
    margin: 0 auto;
  }

  .embed-1080p {
    max-width: 1920px;
    max-height: 1080px;
    margin: 0 auto;
  }
}
</style>

